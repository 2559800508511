import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as icons from '@fortawesome/free-solid-svg-icons'
import api from './api';
import { useStore as zustand } from './zustand';
import { toast } from 'sonner';

export const randomString = (length = 8) => {
  const chars = "abcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
};

export const checkValidUrl = (string:string) => {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
};

export const GoToRoute = (route:string) => {
  window.location.href = route;
};

export const randomNumber = (min:number, max:number) => {
  return Math.floor(Math.random() * (max - min) + min);
}

let general = {
  randomString,
  randomNumber,
  checkValidUrl,
  GoToRoute,
  FontAwesomeIcon,
  api,
  zustand,
  toast
};

export default general;
