import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import DefaultLoadingArezdot from '../../components/DefaultLoadingArezdot';
import Helmet from 'react-helmet';

const NotFound = (props: any) => {
    const controller = useController();

    return (
        <S.mainContainer>
            <Helmet>
                <title>Arezdot - Página não encontrada</title>
            </Helmet>
            <DefaultLoadingArezdot active />
            <S.mainTitle>Página não encontrada</S.mainTitle>
        </S.mainContainer>
    )
}

export default NotFound;